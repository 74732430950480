import {Component, ElementRef, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {OutlookIconCountsComponent} from '../../financial/health/outlook-icon-counts/outlook-icon-counts.component';
import {OutlookIndicatorComponent} from '../../financial/health/outlook-indicator/outlook-indicator.component';
import {SvgIconComponent} from '@ngneat/svg-icon';
import {SearchItem} from '../../../models/search-item';

@Component({
    selector: 'app-omni-search-combo-box-item',
    imports: [CommonModule, OutlookIconCountsComponent, OutlookIndicatorComponent, SvgIconComponent],
    templateUrl: './omni-search-combo-box-item.component.html',
    styleUrls: ['./omni-search-combo-box-item.component.scss']
})
export class OmniSearchComboBoxItemComponent {
  @Input() item?: SearchItem;
  @Input() first?: boolean;
  @Input() last?: boolean;

  constructor(private host: ElementRef) {}

  focus() {
    this.host.nativeElement.focus();
  }
}
