import {ActivatedRouteSnapshot} from '@angular/router';
import {ReportKind} from './reports/report-kind';
import {BoundedRange} from './reports/report-option';
import {PivotDisplayUnit} from './pivot-table/pivot-display-unit';

/**
 * Provides typed values for Route Parameters
 */
export class RouteParser {
	constructor(
		private route: ActivatedRouteSnapshot,
	) {}

	get MCAGs(): Array<string> | undefined {
		return this.route.paramMap.get('mcags')?.split(',');
	}

	get report(): ReportKind | undefined {
		const maybeReportString = this.route.paramMap.get('report');
		if (maybeReportString) {
			if (Object.values(ReportKind).includes(maybeReportString as ReportKind)) {
				return maybeReportString as ReportKind;
			}
			// note: do not throw an error if report not found, simple return undefined (this way, a report that is not found can be handled by the report guard appropriately)
		}

		return undefined;
	}

	get years(): BoundedRange | undefined {
		const maybeYearsString = this.route.paramMap.get('years');
		if (maybeYearsString) {
			const values = maybeYearsString.split(',');
			const numbers = values.map(v => Number.parseInt(v));
			// todo handle NaN and incorrect length
			return [numbers[0], numbers[1]];
		}
		return undefined;
	}

	get displayYear(): number | undefined {
		const maybeYearString = this.route.paramMap.get('displayYear');
		if (maybeYearString) {
			return Number.parseInt(maybeYearString);
		}
		return undefined;
	}

	get excludeInternalServiceFunds(): boolean | undefined {
		const maybeExcludeInternalServiceFundString = this.route.paramMap.get('excludeInternalServiceFunds');
		if (maybeExcludeInternalServiceFundString) {
			return maybeExcludeInternalServiceFundString === 'true';
		}
		return undefined;
	}

	get fundNode() {
		const maybeExcludeInternalServiceFundString = this.route.paramMap.get('fundNode');
		if (maybeExcludeInternalServiceFundString) {
			return maybeExcludeInternalServiceFundString === 'true';
		}
		return undefined;
	}

	get displayUnit(): PivotDisplayUnit | undefined {
		const maybeDisplayUnitString = this.route.paramMap.get('displayUnit');
		if (maybeDisplayUnitString) {
			return maybeDisplayUnitString as PivotDisplayUnit;
		}
		return undefined;
	}

	get showAccountNumbers(): boolean | undefined {
		const maybeShowAccountNumbersString = this.route.paramMap.get('showAccountNumbers');
		if (maybeShowAccountNumbersString) {
			return maybeShowAccountNumbersString === 'true';
		}
		return undefined;
	}

	get byExpenditureObjects(): boolean | undefined {
		const maybeByExpenditureObjectsString = this.route.paramMap.get('byExpenditureObjects');
		if (maybeByExpenditureObjectsString) {
			return maybeByExpenditureObjectsString === 'true';
		}
		return undefined;
	}
}
