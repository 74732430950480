export type IsEqual<T> = (a: T, b: T) => boolean;

export class Comparator {
	static primitive<T extends string | number | boolean | bigint | symbol>(a: T, b: T): boolean {
		return a === b;
	}
	static reference<T extends unknown>(a: T, b: T): boolean {
		return a === b;
	}
	static entries<T extends Array<number | string>>(a: T, b: T): boolean {
		return a.toString() === b.toString();
	}
}
