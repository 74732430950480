import {ChangeDetectorRef, Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavigationComponent} from './layout/navigation/navigation.component';
import {HeaderComponent} from './layout/header/header.component';
import {ContentComponent} from './layout/content/content.component';
import {CornerComponent} from './layout/corner/corner.component';
import {ProgressBarComponent} from './components/progress-bar/progress-bar.component';
import {Layout, RouteService} from './services/route.service';
import {AppLayoutService} from './services/app-layout.service';
import {OmniSearchComponent} from './components/omni-search/omni-search.component';
import {AnalyticsService} from './services/analytics.service';
import {HotkeysService} from './services/hotkeys.service';
import {LoggerService} from './services/logger.service';
import {SearchByNameService} from './components/search-by-name/search-by-name.service';
import {SearchByNameComponent} from './components/search-by-name/search-by-name.component';

@Component({
    selector: 'app-root',
	standalone: true,
	imports: [CommonModule, NavigationComponent, HeaderComponent, ContentComponent, CornerComponent, ProgressBarComponent, OmniSearchComponent, SearchByNameComponent],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'Financial Intelligence Tool';

	isLoading = this.routeService.isLoading;

	isSpaciousLayout = false;

	isMenuVisible = false;
	isContentScrolled = false;
	isNavigationScrolled = false;

	constructor(
		private changeDetector: ChangeDetectorRef,
		public appLayoutService: AppLayoutService,
		protected searchByNameService: SearchByNameService,
		private routeService: RouteService,
		private analytics: AnalyticsService,
		// This service starts itself, but needs to be dependency injected as early as possible
		private hotkeys: HotkeysService,
		private logger: LoggerService
	) {
		this.analytics.init();

		this.routeService.layout.subscribe(layout => this.isSpaciousLayout = layout === Layout.spacious);

		this.appLayoutService.contentScrolled.subscribe(scrolled => {
			this.isContentScrolled = scrolled;
			this.changeDetector.detectChanges();
		});

		this.appLayoutService.navigationScrolled.subscribe(scrolled => {
			this.isNavigationScrolled = scrolled;
			this.changeDetector.detectChanges();
		});
	}

}
