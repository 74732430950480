import {ActivatedRouteSnapshot, ResolveFn} from '@angular/router';
import {map} from 'rxjs/operators';
import {inject} from '@angular/core';
import {FitApiService} from '../api/fit-api/fit-api.service';
import {LocalGovernment} from '../api/fit-api/models/local-government';
import {GovernmentId} from '../api/fit-api/models/government-id';
import {MCAG} from '../api/fit-api/models/mcag';
import {InvalidGovernment, InvalidGovernmentType} from '../../models/invalid-government';
import {RouteParser} from '../../models/route-parser';

/**
 * Resolve and return an array of Local Governments
 * (and Invalid Governments if any MCAG in the list does not return a Local Government)
 *
 * @param route
 * @param state
 */
export const garfieldLocalGovernmentsResolver: ResolveFn<(LocalGovernment | InvalidGovernment)[]> = (route, state) => {
	return resolveGarfieldLocalGovernments(route);
};

export const resolveGarfieldLocalGovernments = (route: ActivatedRouteSnapshot) => {
	const fitApiService = inject(FitApiService);

	// todo right now only retrieves 'mcags', retrieve 'mcag' when retrofitting for single gov use
	const parser = new RouteParser(route);
	const mcags = parser.MCAGs ?? [];

	// filter out gov ids that can't be parsed (are undefined)
	const filteredGovernmentIds = mcags.map(value => GovernmentId.parse(value)).filter(mcag => mcag) as GovernmentId[];

	return fitApiService.getLocalGovernments(filteredGovernmentIds).pipe(map(governments => {
		return mcags.map(mcag => {
			const foundGovernment = governments.find(gov => gov.mcag === mcag as MCAG);
			if (foundGovernment) {
				return foundGovernment;
			} else {
				return new InvalidGovernment(mcag, InvalidGovernmentType.noGovernmentForProvidedCode);
			}
		});
	}));
}

