import { ResolveFn } from '@angular/router';
import {GarfieldReport} from '../../models/reports/garfield-report';

/**
 * Resolve/generate report based on report kind parameter and route context
 *
 * @param route
 * @param state
 */
export const garfieldFinancialReportResolver: ResolveFn<GarfieldReport | undefined> = (route, state) => {
	return GarfieldReport.fromRoute(route);
};
