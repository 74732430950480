import {GovTypeCode} from './gov-type-code';
import {MCAG} from './mcag';
import {GovernmentSpecificity} from './government-specificity';
import {ActivatedRouteSnapshot} from '@angular/router';

/**
 * Identifies a unit of Government that can be addressed.
 */
export class GovernmentId {

	/**
	 * Logical domain.
	 */
	specificity: GovernmentSpecificity;

	/**
	 * The associated parameter name for use with OData queries, etc.
	 */
	parameterName: 'mcag' | 'govTypeCode';

	constructor(
		public id: MCAG | GovTypeCode
	) {
		// todo can I make this a type guard?
		if (id.length === 4) {
			this.specificity = GovernmentSpecificity.Government;
			this.parameterName = 'mcag';
		} else if (id.length === 2) {
			this.specificity = GovernmentSpecificity.GovernmentType;
			this.parameterName = 'govTypeCode';
		} else {
			throw new Error(`'${id}' is not an MCAG or GovTypeCode.`);
		}
	}

	toString = (): string => this.id;

	/**
	 * Generate a GovernmentId from a route, or return undefined if a GovernmentId cannot be resolved.
	 * @param {ActivatedRouteSnapshot} route
	 * @returns {GovernmentId | undefined}
	 *
	 * @deprecated use parse instead
	 */
	static fromRoute(route: ActivatedRouteSnapshot): GovernmentId | undefined {
		const govTypeCode = route.paramMap.get('govTypeCode') as GovTypeCode;
		const mcag = route.paramMap.get('mcag') as MCAG;
		return (!govTypeCode && !mcag) ? undefined : new GovernmentId(mcag || govTypeCode);
	}

	/**
	 * Return GovernmentId for string value, if no governmentId can be created return undefined
	 *
	 * @returns {GovernmentId | undefined}
	 * @param value
	 */
	static parse(value: string): GovernmentId | undefined {
		try {
			return new GovernmentId(value as (MCAG | GovTypeCode));
		} catch {
			return undefined;
		}

	}
}
