import {Memoize} from 'typescript-memoize';
import {Comparator, IsEqual} from '../comparator';
import {GarfieldReport} from './garfield-report';
import {RouteParser} from '../route-parser';
import {PivotDisplayUnit} from '../pivot-table/pivot-display-unit';
import {intersection} from '../../services/functions/array/intersection';

export abstract class ReportOption<Values = any, Value = any> {
	abstract routeParameterKey: string;

	// Provide implementation specific values getter
	protected abstract _values: () => Values;
	@Memoize()
	get values(): Values {
		return this._values();
	}

	// Provide implementation specific default value getter
	protected abstract _defaultValue: () => Value;
	@Memoize()
	get defaultValue(): Value {
		return this._defaultValue();
	}

	@Memoize()
	get appliedValue(): Value {
		// @ts-ignore todo fix this type. It's doing double duty as both the getter to call and also the parameter to use
		//  in a RouterLink
		return new RouteParser(this.report.route)[this.routeParameterKey] ?? this.defaultValue;
	}

	// default to "by reference"
	readonly comparator: IsEqual<Value> = Comparator.reference

	constructor(
		public readonly report: GarfieldReport
	) {
	}

	/**
	 * Is this the default value?
	 * @param value If not provided, tests against appliedValue
	 */
	isDefaultValue = (value = this.appliedValue): boolean =>
		this.comparator(value, this.defaultValue);
}

// /**
//  * Multi Year Slider
//  */
// // Tuple representing the beginning and end of a range of integers [start, end]
// // Note: This can be a little tricky to work with, so we may consider making it a class to enforce behavior
export type BoundedRange = [number, number];
//
// export class MultiYearReportOption extends ReportOption<
// 	// This enforces types and provides type info in the HTML template
// 	Set<number>, BoundedRange
// > {
// 	override routeParameterKey = 'years';
//
// 	protected _values = () =>
// 		new Set(this.report.datasetsDetail.map(x => x.detail.includedYears).flat());
//
// 	protected _defaultValue = () =>
// 		[
// 			Array.from(this.values).slice(-4, -3)[0],
// 			Array.from(this.values).slice(-1)[0]
// 		] satisfies BoundedRange;
//
// 	override comparator = Comparator.entries;
// }

/**
 * Single Year Slider
 */
export class SingleYearReportOption extends ReportOption<
	Set<number>, number
> {
	override routeParameterKey = 'displayYear';

	protected _values = () => new Set(this.report.datasetsDetail.map(x => x.detail.includedYears).flat()); // union of all years

	// Default value is the latest year the resolved datasets have in common
	protected _intersectedYears = intersection(this.report.datasetsDetail.map(x => x.detail.includedYears));
	protected _defaultValue = () => Math.max(...this._intersectedYears);

	//override comparator = Comparator.entries; // todo ask about
}
//
// /**
//  * Fund Selector
//  */
// export class ExcludeInternalServiceFundOption extends ReportOption<
// 	Set<boolean>, boolean
// > {
// 	override routeParameterKey = 'excludeInternalServiceFunds';
//
// 	protected _values = () => new Set([true, false]);
//
// 	protected _defaultValue = () => true;
//
// 	override comparator = Comparator.primitive; // todo ask about
// }
//
// export class SelectedFundOption extends ReportOption<
// 	Set<number>, BoundedRange
// > {
// 	override routeParameterKey = 'fundNode';
//
// 	protected _values = () =>
// 		new Set(this.report.datasetsDetail.map(x => x.detail.includedYears).flat());
//
// 	protected _defaultValue = () =>
// 		[
// 			Array.from(this.values).slice(-4, -3)[0],
// 			Array.from(this.values).slice(-1)[0]
// 		] satisfies BoundedRange;
//
// 	override comparator = Comparator.entries;
// }
//
// /**
//  * Display Options
//  */
// export class DisplayUnitDisplayOption extends ReportOption<
// 	Set<PivotDisplayUnit>, PivotDisplayUnit
// > {
// 	override routeParameterKey = 'displayUnit';
//
// 	protected _values = () => new Set(Object.values(PivotDisplayUnit));
//
// 	protected _defaultValue = () => PivotDisplayUnit.dollars;
//
// 	// override comparator = Comparator.entries;
// }
//
// export class ShowAccountNumbersDisplayOption extends ReportOption<
// 	Set<boolean>, boolean
// > {
// 	override routeParameterKey = 'showAccountNumbers';
//
// 	protected _values = () => new Set([true, false]);
//
// 	protected _defaultValue = () => false;
//
// 	override comparator = Comparator.primitive; // todo ask about
// }

export class ByExpenditureObjectsDisplayOption extends ReportOption<
	Set<boolean>, boolean
> {
	override routeParameterKey = 'byExpenditureObjects';

	protected _values = () => new Set([true, false]);

	protected _defaultValue = () => false;

	override comparator = Comparator.primitive; // todo ask about
}
