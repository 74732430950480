import {AppComponent} from './app/app.component';
import {bootstrapApplication, provideProtractorTestingSupport} from '@angular/platform-browser';
import {
	PreloadAllModules,
	provideRouter,
	TitleStrategy,
	withComponentInputBinding,
	withPreloading, withRouterConfig
} from '@angular/router';
import appRoutes from './app/routes';
import {
	FinancialIntelligenceToolPageTitleStrategy
} from './app/services/router/financial-intelligence-tool-page-title-strategy';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptors} from '@angular/common/http';
import {ApiInterceptor} from './app/services/interceptors/api-interceptor';
import {provideSvgIconsConfig} from '@ngneat/svg-icon';
import {infoOutlineIcon} from './app/svg/info-outline';
import {exploreIcon} from './app/svg/explore';
import {locationIcon} from './app/svg/location';
import {govTypeIcon} from './app/svg/gov-type';
import {financesIcon} from './app/svg/finances';
import {filingIcon} from './app/svg/filing';
import {dataIcon} from './app/svg/data';
import {resourcesIcon} from './app/svg/resources';
import {healthIcon} from './app/svg/health';
import {infoSolidIcon} from './app/svg/info-solid';
import {helpIcon} from './app/svg/help';
import {docIcon} from './app/svg/doc';
import {menuIcon} from './app/svg/menu';
import {closeIcon} from './app/svg/close';
import {settingsIcon} from './app/svg/settings';
import {savedIcon} from './app/svg/saved';
import {loginIcon} from './app/svg/login';
import {bookmarkIcon} from './app/svg/bookmark';
import {resourcesInfoIcon} from './app/svg/resources-info';
import {alertIcon} from './app/svg/alert';
import {arrowDownIcon} from './app/svg/arrow-down';
import {arrowUpIcon} from './app/svg/arrow-up';
import {calendarIcon} from './app/svg/calendar';
import {checkIcon} from './app/svg/check';
import {chevronDownIcon} from './app/svg/chevron-down';
import {chevronLeftIcon} from './app/svg/chevron-left';
import {chevronRightIcon} from './app/svg/chevron-right';
import {collapseIcon} from './app/svg/collapse';
import {dataExtractIcon} from './app/svg/data-extract';
import {deleteIcon} from './app/svg/delete';
import {doneIcon} from './app/svg/done';
import {expandIcon} from './app/svg/expand';
import {externalIcon} from './app/svg/external';
import {noDataIcon} from './app/svg/no-data';
import {reloadIcon} from './app/svg/reload';
import {searchIcon} from './app/svg/search';
import {targetIcon} from './app/svg/target';
import {timeFillIcon} from './app/svg/time-fill';
import {timeOutlineIcon} from './app/svg/time-outline';
import {warningIcon} from './app/svg/warning';
import {unknownIcon} from './app/svg/unknown';
import {penToSquareSolidIcon} from './app/svg/pen-to-square-solid';
import {eyeSolidIcon} from './app/svg/eye-solid';
import {eyeSlashSolidIcon} from './app/svg/eye-slash-solid';
import {thumbtackSolidIcon} from './app/svg/thumbtack-solid';
import {plusSolidIcon} from './app/svg/plus-solid';
import {trashSolidIcon} from './app/svg/trash-solid';
import {heartbeatIcon} from './app/svg/heartbeat';
import {arrowUpRightIcon} from './app/svg/arrow-up-right';
import {arrowUpRightFromSquareIcon} from './app/svg/arrow-up-right-from-square';
import {gearSolidIcon} from './app/svg/gear-solid';
import {listIcon} from './app/svg/list';
import {trophyIcon} from './app/svg/trophy';
import {downloadIcon} from './app/svg/download';
import {lockOpenSolidIcon} from './app/svg/lock-open-solid';
import {equalsSolidIcon} from './app/svg/equals-solid';
import {resetIcon} from './app/svg/reset';
import {cameraSolidIcon} from './app/svg/camera-solid';
import {databaseSolidIcon} from './app/svg/database-solid';
import {logoutIcon} from './app/svg/logout';
import {filterIcon} from './app/svg/filter';
import {
	CurrencyPipe,
	DatePipe,
	DecimalPipe,
	LowerCasePipe,
	PercentPipe,
	TitleCasePipe,
	UpperCasePipe
} from '@angular/common';
import {ZeroPercentPipe} from './app/services/pipes/zero-percent.pipe';
import {PxToRemPipe} from './app/services/pipes/px-to-rem.pipe';
import {FilterPipe} from './app/services/pipes/filter.pipe';
import {CurrencySuffixPipe} from './app/services/pipes/currency-suffix.pipe';
import {provideAnimations} from '@angular/platform-browser/animations';
import {InstanceofPipe} from './app/services/pipes/instanceof.pipe';
import {OutlookIndicatorPipe} from './app/services/pipes/outlook-indicator.pipe';
import {provideCharts, withDefaultRegisterables} from 'ng2-charts';
import {UserBasedPreloadingStrategy} from './app/services/preloading-strategies/user-based-preloading-strategy';

bootstrapApplication(AppComponent, {
	providers: [
		provideCharts(withDefaultRegisterables()),
		provideAnimations(),
		provideRouter(
			appRoutes,
			// Send params/resolved data directly to Component @Inputs
			//  https://angular.dev/guide/routing/common-router-tasks#add-withcomponentinputbinding
			withComponentInputBinding(),
			withRouterConfig({
				// Also provide parent params/data to children
				paramsInheritanceStrategy: 'always'
			}),
			// Custom preload strategy (only loads fit-admin components when the user is signed in with global access)
			withPreloading(UserBasedPreloadingStrategy),
			// withDebugTracing()
		),
		provideHttpClient(
			withInterceptors([
				ApiInterceptor
			])
		),
		provideProtractorTestingSupport(),
		// Provide a custom title strategy
		{ provide: TitleStrategy, useClass: FinancialIntelligenceToolPageTitleStrategy },
		{ provide: HTTP_INTERCEPTORS, useValue: ApiInterceptor, multi: true },
		provideSvgIconsConfig({
			icons: [
				alertIcon, arrowDownIcon, arrowUpIcon, bookmarkIcon, calendarIcon, checkIcon, chevronDownIcon,
				chevronDownIcon, chevronLeftIcon, chevronRightIcon, closeIcon, collapseIcon, dataIcon, dataExtractIcon,
				deleteIcon, docIcon, doneIcon, expandIcon, exploreIcon, externalIcon, filingIcon, financesIcon,
				govTypeIcon, healthIcon, helpIcon, infoOutlineIcon, infoSolidIcon, locationIcon, loginIcon, logoutIcon, menuIcon,
				noDataIcon, reloadIcon, resourcesIcon, resourcesInfoIcon, savedIcon, searchIcon, settingsIcon,
				targetIcon, timeFillIcon, timeOutlineIcon, warningIcon, penToSquareSolidIcon, eyeSolidIcon,
				eyeSlashSolidIcon, thumbtackSolidIcon, plusSolidIcon, trashSolidIcon, heartbeatIcon, arrowUpRightIcon,
				gearSolidIcon, listIcon, trophyIcon, downloadIcon, lockOpenSolidIcon, equalsSolidIcon, resetIcon, cameraSolidIcon,
				databaseSolidIcon, filterIcon, arrowUpRightFromSquareIcon
			],
			missingIconFallback: unknownIcon
		}),
		TitleCasePipe, PercentPipe, UpperCasePipe, LowerCasePipe, ZeroPercentPipe, CurrencySuffixPipe, PxToRemPipe, DecimalPipe, CurrencyPipe, DatePipe, InstanceofPipe, FilterPipe, OutlookIndicatorPipe
	]
});
