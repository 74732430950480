import {localGovernmentResolver} from './services/resolvers/local-government.resolver';
import {governmentNameResolver} from './services/resolvers/government-name.resolver';
import {governmentIdCanActivateDatasetGuard} from './services/guards/government-id-can-activate-dataset.guard';
import {governmentIdDatasetResolver} from './services/resolvers/government-id-dataset.resolver';
import {extractTitleResolver} from './services/resolvers/extract-title.resolver';
import {extractTypeResolver} from './services/resolvers/extract-type.resolver';
import {Layout} from './services/route.service';
import {governmentTypeResolver} from './services/resolvers/government-type.resolver';
import {governmentTypeNameResolver} from './services/resolvers/government-type-name.resolver';
import {dataExtractCanActivateDatasetGuard} from './services/guards/data-extract-can-activate-dataset.guard';
import {dollarsTitleResolver} from './services/resolvers/dollars-title.resolver';
import {dollarsLogicalAccountResolver} from './services/resolvers/dollars-logical-account.resolver';
import {dollarsBreadcrumbsResolver} from './services/resolvers/dollars-breadcrumbs.resolver';
import {financialHealthDatasetsResolver} from './services/resolvers/financial-health-datasets.resolver';
import {extractDatasetResolver} from './services/resolvers/extract-dataset.resolver';
import {aboutDatasetResolver} from './services/resolvers/about-dataset.resolver';
import {adminSnapshotDatasetResolver} from './services/resolvers/admin-snapshot-dataset.resolver';
import {adminCanActivateSnapshotDatasetGuard} from './services/guards/admin-can-activate-snapshot-dataset.guard';
import {FitRoute} from './fit-route';
import {financialReportGuard} from './services/guards/financial-report.guard';
import {financialReportResolver} from './services/resolvers/financial-report.resolver';
import {financialReportTitleResolver} from './services/resolvers/financial-report-title.resolver';
import {
	financialHealthIndicatorGroupsResolver
} from './services/resolvers/financial-health-indicator-groups.resolver.service';
import {financialHealthIndicatorResolver} from './services/resolvers/financial-health-indicator.resolver.service';
import {financialHealthIndicatorGuard} from './services/guards/financial-health-indicator.guard.service';
import {
	financialHealthIndicatorTitleResolver
} from './services/resolvers/financial-health-indicator-title.resolver.service';
import {byDollarsDatasetsResolver} from './services/resolvers/by-dollars-datasets.resolver';
import {garfieldLocalGovernmentsResolver} from './services/resolvers/garfield-local-governments.resolver';
import {garfieldDatasetsDetailResolver} from './services/resolvers/garfield-datasets-detail.resolver';
import {garfieldFinancialReportResolver} from './services/resolvers/garfield-financial-report.resolver';
import {garfieldFinancialReportGuard} from './services/guards/garfield-financial-report.guard';
import {GovernmentComparisonContext} from './models/reports/garfield-report';

const routes: Array<FitRoute> = [
	{ path: '', redirectTo: 'explore', pathMatch: 'full' },
	{
		path: 'explore',
		loadComponent: () => import('./pages/explore/explore.component'),
		data: {
			layout: Layout.spacious,
			hasOmniSearch: true
		}
	},
	{
		path: 'explore/government',
		title: 'Individual Governments',
		loadComponent: () => import('./pages/government-search/government-search.component')
	},
	{
		path: 'explore/government/:mcag',
		canActivate: [governmentIdCanActivateDatasetGuard], // rewrite url if resolved Dataset is not available to user or for government
		title: governmentNameResolver, // put the Government name in the browser title/history
		loadComponent: () => import('./pages/government-profile/government-profile.component'),
		resolve: { // resources required to draw the page (i.e. not sub-component data)
			localGovernment: localGovernmentResolver,
			dataset: governmentIdDatasetResolver
		}
	},
	{
		path: 'explore/government/:mcag/health/:indicatorCode',
		title: financialHealthIndicatorTitleResolver,
		canActivate: [governmentIdCanActivateDatasetGuard, financialHealthIndicatorGuard],
		loadComponent: () => import('./pages/financial-health-indicator/financial-health-indicator.component'),
		resolve: {
			localGovernment: localGovernmentResolver,
			dataset: governmentIdDatasetResolver,
			indicator: financialHealthIndicatorResolver,
			indicatorGroups: financialHealthIndicatorGroupsResolver
		}
	},
	{
		path: 'explore/government/:mcag/health',
		redirectTo: 'explore/government/:mcag/health/foo', // 'foo' param gets picked up by financialHealthIndicatorGuard and changed to correct indicator (default indicator)
	},
	{
		path: 'explore/government/:mcag/finances/:report',
		title: financialReportTitleResolver,
		canActivate: [governmentIdCanActivateDatasetGuard, financialReportGuard],
		loadComponent: () => import('./pages/financial-report/financial-report.component'),
		resolve: {
			localGovernment: localGovernmentResolver,
			dataset: governmentIdDatasetResolver,
			report: financialReportResolver,
		}
	},
	{
		// note: redirect to summary page must follow endpoint that takes report param in route definitions in order to work correctly
		path: 'explore/government/:mcag/finances',
		redirectTo: 'explore/government/:mcag/finances/summary',
	},
	{
		path: 'explore/government-type',
		title: 'Government Types',
		loadComponent: () => import('./pages/government-type-selection/government-type-selection.component')
	},
	{
		path: 'explore/government-type/:govTypeCode',
		canActivate: [governmentIdCanActivateDatasetGuard], // rewrite url if resolved Dataset is not available to user or for government
		title: governmentTypeNameResolver, // put the Government Type name in the browser title/history
		loadComponent: () => import('./pages/government-type-profile/government-type-profile.component'),
		resolve: { // resources required to draw the page (i.e. not sub-component data)
			governmentType: governmentTypeResolver,
			dataset: governmentIdDatasetResolver
		}
	},
	{
		path: 'explore/government-type/:govTypeCode/finances/:report',
		title: financialReportTitleResolver,
		canActivate: [governmentIdCanActivateDatasetGuard, financialReportGuard],
		loadComponent: () => import('./pages/financial-report/financial-report.component'),
		resolve: {
			governmentType: governmentTypeResolver,
			dataset: governmentIdDatasetResolver,
			report: financialReportResolver
		}
	},
	{
		// note: redirect to summary page must follow endpoint that takes report param in route definitions in order to work correctly
		path: 'explore/government-type/:govTypeCode/finances',
		redirectTo: 'explore/government-type/:govTypeCode/finances/summary',
	},
	{
		// todo this is a temporary shim, remove when debt and liabilities gets added to government comparison
		path: 'explore/government/:mcags/comparison/debt-liabilities',
		redirectTo: 'explore/government/:mcags/comparison/summary',
	},
	{
		path: 'explore/government/:mcags/comparison/:report',
		title: 'Government Comparison',
		canActivate: [garfieldFinancialReportGuard],
		loadComponent: () => import('./pages/government-comparison/government-comparison.component'),
		resolve: {
			localGovernments: garfieldLocalGovernmentsResolver,
			datasetsDetail: garfieldDatasetsDetailResolver,
			report: garfieldFinancialReportResolver
		},
		data: {
			context: new GovernmentComparisonContext()
		}
	},
	{
		// note: redirect to summary page must follow endpoint that takes report param in route definitions in order to work correctly
		path: 'explore/government/:mcags/comparison',
		redirectTo: 'explore/government/:mcags/comparison/summary',
	},
	{
		path: 'explore/financial-health',
		title: 'Financial Health',
		loadComponent: () => import('./pages/financial-health-profile/financial-health-profile.component'),
		resolve: {
			datasets: financialHealthDatasetsResolver
		}
	},
	{
		path: 'explore/dollars',
		title: 'Navigate by Dollars',
		loadComponent: () => import('./pages/dollars-selection/dollars-selection.component'),
		resolve: {
			datasets: byDollarsDatasetsResolver
		}
	},
	{
		path: 'explore/dollars/bars/:logicalAccount',
		title: dollarsTitleResolver,
		loadComponent: () => import('./pages/dollars-profile/dollars-profile.component'),
		resolve: {
			datasets: byDollarsDatasetsResolver,
			logicalAccount: dollarsLogicalAccountResolver,
			breadcrumbs: dollarsBreadcrumbsResolver // this overrides the default breadcrumb generation
		},
		children: [
			{
				path: '', redirectTo: 'overview', pathMatch: 'full'
			},
			{
				path: 'overview',
				loadComponent: () => import('./pages/dollars-profile/dollars-overview/dollars-overview.component')
			},
			{
				path: 'governments',
				loadComponent: () => import('./pages/dollars-profile/dollars-by-government/dollars-by-government.component')
			},
			{
				path: 'government-types',
				loadComponent: () => import('./pages/dollars-profile/dollars-by-government-type/dollars-by-government-type.component')
			}
		]
	},
	{
		// Only shown in the manu for development
		path: 'style-guide',
		title: 'Style Guide',
		loadComponent: () => import('./pages/style-guide/style-guide.component'),
	},
	{
		path: 'style-guide/dialogs',
		loadComponent: () => import('./pages/style-guide/dialogs/dialogs.component'),
		title: 'Style Guide - Dialogs'
	},
	{
		path: 'fit-admin',
		title: 'Administration',
		canActivate: [adminCanActivateSnapshotDatasetGuard],
		runGuardsAndResolvers: 'always',  // lets us reload the nav route and re-execute the guard and resolver
		loadComponent: () => import('./pages/admin/admin.component'),
		resolve: {
			snapshotDataset: adminSnapshotDatasetResolver
		}
	},
	{
		path: 'fit-admin/publish-snapshot',
		canActivate: [adminCanActivateSnapshotDatasetGuard],
		title: 'Publish New Online Filing Snapshot',
		loadComponent: () => import('./pages/admin/admin-publish-snapshot/admin-publish-snapshot.component'),
		resolve: {
			snapshotDataset: adminSnapshotDatasetResolver
		}
	},
	{
		path: 'fit-admin/delete-snapshots',
		title: 'Delete Old Snapshots',
		loadComponent: () => import('./pages/admin/admin-delete-snapshots/admin-delete-snapshots.component'),
	},
	{
		path: 'fit-admin/promoted-links',
		title: 'Promoted Links',
		loadComponent: () => import('./pages/admin/admin-promoted-links/admin-promoted-links.component'),
	},
	{
		path: 'fit-admin/population-importer',
		title: 'Population Importer',
		loadComponent: () => import('./pages/admin/admin-population-importer/admin-population-importer.component'),
		children: [
			{
				path: '', redirectTo: 'vintages', pathMatch: 'full'
			},
			{
				path: 'vintages',
				title: 'Population Importer - Vintages',
				loadComponent: () => import('./pages/admin/admin-population-importer/population-vintages/population-vintages.component'),
			},
			{
				path: 'records/:vintageYear',
				title: (route) => `Population Importer - Import & Maintain Data for ${route.params['vintageYear']}`,
				loadComponent: () => import('./pages/admin/admin-population-importer/population-records/population-records.component')
			}
		]
	},
	{
		path: 'data-extracts',
		loadComponent: () => import('./pages/data-extracts/data-extracts.component'),
		title: 'Data Extracts'
	},
	{
		path: 'data-extracts/:extractTypeId',
		canActivate: [dataExtractCanActivateDatasetGuard], // rewrite url if resolved Dataset is not available for extractTypeId
		loadComponent: () => import('./pages/data-extracts/extract/extract.component'),
		title: extractTitleResolver,
		resolve: { // resources required to draw the page (i.e. not sub-component data)
			extractType: extractTypeResolver,
			dataset: extractDatasetResolver
		},
	},
	{
		path: 'about',
		loadComponent: () => import('./pages/about/about.component'),
		title: 'About FIT',
		resolve: {
			dataset: aboutDatasetResolver
		}
	},
	{
		path: 'help',
		loadComponent: () => import('./pages/help/help.component'),
		title: 'Help'
	},
	{
		path: 'not-authorized',
		title: 'Not Authorized',
		loadComponent: () => import('./pages/page-not-authorized/page-not-authorized.component'),
	},
	{
		path: 'page-error',
		title: 'Error',
		loadComponent: () => import('./pages/page-error/page-error.component'),
	},
	{
		path: '**',
		title: 'Page Not Found',
		loadComponent: () => import('./pages/page-not-found/page-not-found.component'),
	}
];
export default routes;
