import {CanActivateFn, Router} from '@angular/router';
import {inject} from '@angular/core';
import {UserService} from '../user.service';
import {GarfieldReport, isApplicable} from '../../models/reports/garfield-report';

/**
 * Determine if the report is valid
 *
 * @param route
 * @param state
 */
export const garfieldFinancialReportGuard: CanActivateFn = async (route, state) => {
	const router = inject(Router);
	const userService = inject(UserService);
	const foundValidReport = await GarfieldReport.fromRoute(route);

	if (foundValidReport) {
		// If the report matches in the report definitions:
		// Check to see if the report requires a certain level of authorization
		// and then check to see if the user is authorized to see the report.
		// Navigate the user to ‘/not-authorized page’ if not.
		if (foundValidReport.restrictedTo) {
			userService.user.subscribe(user => {
				if (isApplicable(foundValidReport, user)) {
					return true;
				} else {
					router.navigate(['/not-authorized']);
					return false;
				}
			});
		}

		return true;
	} else {
		// If the report route parameter isn't found in report definitions, navigate the user to ‘/page-error’ page with reason
		router.navigate(['/page-error', {reason: `No government comparison report was found for the provided name (${route.paramMap.get('report')})`}]).then();
		return false;
	}
};
