import {Component, HostBinding, Input} from '@angular/core';
import {CommonModule, LowerCasePipe} from '@angular/common';
import {OutlookIndicator} from '../../../../models/outlook-indicator';
import {SvgIconComponent} from '@ngneat/svg-icon';
import FinancialHealthService from '../../../../services/financial-health.service';
import {OutlookInfo} from '../../../../services/api/fit-api/models/indicators/outlook-info';

/**
 * Displays the Financial Health Outlook (Good, Cautionary, etc.) with the associated icon
 * when provided with an OutlookInfo input (works for single indicators, indicator groups, etc.)
 *
 * If provided with an OutlookCount input, we display the icon, but then the count rather than the Outlook state.
 */
@Component({
    selector: 'app-outlook-indicator',
    imports: [CommonModule, SvgIconComponent],
    templateUrl: './outlook-indicator.component.html',
    styleUrls: ['./outlook-indicator.component.scss']
})
export class OutlookIndicatorComponent {
	// Hardcode size; if left undefined, will follow component media rules
	@Input() size?: 'small' | 'medium' | 'large' | 'x-large';
	@Input() financialHealthProfileDisableOption = false;

	private _outlookIndicator?: OutlookIndicator;
	@Input()
	set outlookIndicator(value: OutlookIndicator | Array<OutlookIndicator> | Array<OutlookInfo> | undefined) {
		if (Array.isArray(value)) {
			this._outlookIndicator = FinancialHealthService.aggregateOutlook(value);
		} else {
			this._outlookIndicator = value;
		}
	}
	get outlookIndicator(): OutlookIndicator | undefined {
		return this._outlookIndicator;
	}

	@HostBinding('class')
	get classes() {
		let className: string;

		if (this.outlookIndicator?.count === 0 || this.financialHealthProfileDisableOption) {
			className = 'zero';
		} else {
			className = this.lowerCasePipe.transform(this.outlookIndicator?.outlook) as string;
		}

		return this.size
			? [ this.size, className ]
			: className;
	}

	constructor(
		private lowerCasePipe: LowerCasePipe
	) {	}

}
