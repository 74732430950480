import {Component, ViewChild} from '@angular/core';
import {CommonModule, Location, NgOptimizedImage} from '@angular/common';
import {OmniSearchComponent} from '../../components/omni-search/omni-search.component';
import {RouterLink} from '@angular/router';
import {SvgIconComponent} from '@ngneat/svg-icon';
import {UserService} from '../../services/user.service';
import {User} from '../../models/user';
import {LayoutClassDirective} from '../../services/directives/layout-class.directive';
import {RouteService} from '../../services/route.service';
import {DialogComponent} from '../../components/modal-dialog/dialog.component';

@Component({
    selector: 'app-header',
    imports: [CommonModule, OmniSearchComponent, RouterLink, SvgIconComponent, NgOptimizedImage, DialogComponent],
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    host: { 'class': 'content-gutter' },
    hostDirectives: [LayoutClassDirective]
})
export class HeaderComponent {
	@ViewChild('logoutDialog') logoutDialog?: DialogComponent;
	user?: User;

	// todo Shawn (author) to document
	currentLocation?: string;

	// The Explore page implements its own OmniSearch, so we need to remove the one in the header. This
	// is important because we need to be able to focus the OmniSearch with a hotkey and having two on the
	// page, whether visibly hidden or not, will create issues.
	contentHasOmniSearch = this.routeService.hasOmniSearch;

	login = () => this.userService.login(this.currentLocation);

	logout = () => this.userService.logout();

	constructor(
		private location: Location,
		private userService: UserService,
		private routeService: RouteService
	) {
		this.userService.user.subscribe(x =>
			this.user = x
		);
		this.location.onUrlChange(url => this.currentLocation = url);
	}

}
