import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {distinctUntilChanged, fromEvent} from 'rxjs';
import {map} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {SvgIconComponent} from '@ngneat/svg-icon';
import {v4 as uuid} from 'uuid';
import {SvgIcons} from '@ngneat/svg-icon/lib/types';

@UntilDestroy()
@Component({
    selector: 'app-search',
    imports: [CommonModule, SvgIconComponent],
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements AfterViewInit {

	@ViewChild('searchInput') searchInput?: ElementRef<HTMLInputElement>;

	@Input() label: string | null = null;
	@Input() placeholder = 'Search';

	@Input() value = '';
	@Output() valueChange = new EventEmitter<string>();

	_iconKey?: SvgIcons = 'search';
	get iconKey() { return this._iconKey; }
	@Input()
	set iconKey(value) { this._iconKey = value; }

	// Alternate inset text if we don't want an icon; used for components like the search-by-name which indicate "2 of 3"
	_insetText?: string;
	get insetText() { return this._insetText }
	@Input()
	set insetText(insetText) {
		this._insetText = insetText;
		this.iconKey = undefined;
	}

	elementId = uuid();

	ngAfterViewInit(): void {
		if (!this.searchInput?.nativeElement) {
			throw new Error(`#searchInput could not be found. Values will not be emitted.`);
		}
		// emit initial if parent does not set a value
		this.valueChange.emit(this.value);
		fromEvent<InputEvent>(this.searchInput.nativeElement, 'input').pipe(
			untilDestroyed(this),
			map(event => (event.target as HTMLInputElement).value)
		).subscribe(result => {
			if (result !== this.value) {
				this.valueChange.next(result);
			}
		});
	}

	focus() {
		if (this.searchInput) {
			this.searchInput.nativeElement.focus();
		}
	}
}
