import {ActivatedRouteSnapshot, ResolveFn} from '@angular/router';
import {DatasetService} from '../api/fit-api/dataset.service';
import {inject} from '@angular/core';
import {EMPTY, lastValueFrom, map, mergeMap, switchMap, toArray} from 'rxjs';
import {resolveGarfieldLocalGovernments} from './garfield-local-governments.resolver';
import {LocalGovernment} from '../api/fit-api/models/local-government';
import {DatasetDetail} from '../api/fit-api/models/datasets/dataset-type';

/**
 * Resolves array of unique datasets from the list of Local Governments
 * returned using the mcag, govTypeCode, or mcags filters and the resulting local governments’ dataset sources.
 *
 * @param route
 * @param state
 */
export const garfieldDatasetsDetailResolver: ResolveFn<(DatasetDetail)[]> = (route, state) => {
	return getDatasetsDetailFromRoute(route);
};

export const getDatasetsDetailFromRoute = (route: ActivatedRouteSnapshot): Promise<Array<DatasetDetail>> => {
	const datasetService = inject(DatasetService);

	const datasets = resolveGarfieldLocalGovernments(route).pipe(
		mergeMap(governments => {
			return governments.map(government => {
				if (government instanceof LocalGovernment) {
					// note: return dataset detail for full lookups
					// return null for DatasetSource = None
					return datasetService.getDataset(government.financialsDatasetSource).pipe(switchMap(dataset => dataset?.datasetType ? datasetService.getDatasetDetail(dataset?.datasetType) : EMPTY));
				} else {
					return EMPTY; // don't return anything if InvalidGovernment
				}
			});
		}),
		mergeMap(obs => obs), // flatten array of observables
		toArray() // flatten array of observables
	);

	// return unique and non null datasets
	return lastValueFrom(datasets.pipe(map(datasets => Array.from(new Set(datasets)).filter(dataset => dataset !== null) as DatasetDetail[])));
}
