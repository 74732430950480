import {
	AfterViewInit,
	Component,
	ElementRef, HostListener,
	Input,
	OnChanges, OnDestroy, QueryList,
	SimpleChanges,
	ViewChild, ViewChildren
} from '@angular/core';
import {CommonModule, PercentPipe} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {SearchComponent} from '../search/search.component';
import {SearchItem} from '../../../models/search-item';
import {Observable} from 'rxjs';
import {SvgIconComponent} from '@ngneat/svg-icon';
import {Router} from '@angular/router';
import {DownloadDirective} from '../../../services/directives/download.directive';
import {FileService} from '../../../services/file.service';
import {LoadingIndicatorComponent} from '../../../layout/loading-indicator/loading-indicator.component';
import {sortByProperty} from '../../../services/functions/sort-by-property';
import {autoUpdate, computePosition, flip, offset, size} from '@floating-ui/dom';
import {overlayScrollDarkVertical} from '../../../layout/overlay-scroll-options';
import {OverlayscrollbarsModule} from 'overlayscrollbars-ngx';
import {OmniSearchComboBoxItemComponent} from '../omni-search-combo-box-item/omni-search-combo-box-item.component';
import {FocusKeyManager} from '@angular/cdk/a11y';

@Component({
    selector: 'app-combo-box',
    imports: [CommonModule, FormsModule, SearchComponent, SvgIconComponent, LoadingIndicatorComponent, OverlayscrollbarsModule, OmniSearchComboBoxItemComponent],
    templateUrl: './combo-box.component.html',
    styleUrls: ['./combo-box.component.scss']
})
export class ComboBoxComponent extends DownloadDirective implements OnChanges, AfterViewInit, OnDestroy {
	@Input() label: string | null = null;
	@Input() placeholder = 'Search and select one...';
	// Minimum characters to type before responding to text input
	@Input() minChars = 3;
	// Time in ms to wait before responding to text input
	@Input() debounce = 200;
	@Input() sourceItems?: Observable<Array<SearchItem>>;
	// Compact mode allows search to condense into a round magnifying glass button used to display and hide the combobox
	@Input() compactMode = false;

	mobileSearchEnabled = false;

	@ViewChild('comboboxInput') comboboxInput?: ElementRef;
	@ViewChild('searchResults') searchResults?: ElementRef;
	@ViewChild(SearchComponent) searchComponent?: SearchComponent;
	@ViewChild('nextPage') nextPage?: ElementRef;

	// Used for accessibility, keyboard navigation
	@ViewChildren(OmniSearchComboBoxItemComponent) omniSearchComboBoxItems?: QueryList<OmniSearchComboBoxItemComponent>;
	private keyManager?: FocusKeyManager<OmniSearchComboBoxItemComponent>;

	// If not lazy loading from server, store items
	private items: Array<SearchItem> = [];

	// the list after filtering
	private _filteredItems: Array<SearchItem> = [];
	get filteredItems() {
		return this._filteredItems;
	}
	set filteredItems(value: Array<SearchItem>) {
		this._filteredItems = value;
		this.setDisplayItems();  // We always need to set the display items when a new filtered list is assigned
	}

	// the list to be displayed on screen
	readonly MAX_ITEMS_WITHOUT_PAGING = 4;
	readonly MAX_NEXT_PAGE = 8;
	numberToDisplay = 0;
	nextPageCount = 0;
	displayItems: Array<SearchItem> = [];

	// enable or disable visibility of dropdown
	showResults = false;

	// Keeps track of the autoUpdate function in order to cancel it when hiding the popover
	cancelAutoUpdate?: () => void;

	_searchText = '';
	get searchText() {
		return this._searchText;
	}
	set searchText(value: string) {
		this._searchText = value;
		this.filterItems(value);
	}

	constructor(
		private router: Router,
		//private logger: LoggerService,
		fileService: FileService, // For download directive
		percentPipe: PercentPipe, // For download directive
	) {
		super(fileService, percentPipe);
		// console.log('combobox ctor');
	}

	// Focuses the search input for the user to type a query
	focusInput() {
		this.searchComponent?.focus();
	}

	ngAfterViewInit() {
		this.cancelAutoUpdate = autoUpdate(this.comboboxInput?.nativeElement,this.searchResults?.nativeElement,() => {
			computePosition(this.comboboxInput?.nativeElement, this.searchResults?.nativeElement, {
				placement: 'bottom',
				middleware: [
					offset(8),
					flip(),
					size({
						apply({rects, availableHeight, elements}) {
							// Do things with the data, e.g.
							Object.assign(elements.floating.style, {
								width: `${rects.reference.width}px`,
								maxHeight: `${availableHeight - 26}px`,
							});
						},
					}),
				]
			}).then(({x, y}) => {
				Object.assign(this.searchResults?.nativeElement.style, {
					left: `${x}px`,
					top: `${y}px`,
				});
			})
		});

		// Set focus key manager for accessibility, keyboard navigation of list items
		if (this.omniSearchComboBoxItems) {
			this.keyManager = new FocusKeyManager(this.omniSearchComboBoxItems);
		}
	}

	// todo This needs to be cleaned up. There is no visual indication that there is data loading. Once the
	//  data is loaded, the user's query is erased. (Author: Shawn)
	ngOnChanges(changes: SimpleChanges) {
		this.sourceItems?.subscribe(items => {
			this.items = items;
			this.reset();
		});
	}

	// modifies the filtered list as per input
	// Search both the code & description for user's input
	filterItems(searchText: string) {
		if (searchText.length < this.minChars && this.numberToDisplay !== 0) {
			this.reset(false);  // User is using backspace, etc.
		} else {
			this.filteredItems = this.filterAndSortItems(this.items, searchText);
		}
		this.showResults = this.filteredItems.length > 0;
	}

	addNextPage() {
		this.numberToDisplay += this.nextPageCount;
		this.setDisplayItems();

		// If we have exhausted the paging (no more search items to show),
		// then we should shift focus back to another element in the combobox
		if (this.nextPageCount === 0) {
			if (this.searchResults?.nativeElement) {
				// if no more results, set last active item (before 'add next page' was clicked) as active
				this.keyManager?.setLastItemActive();
			} else if (this.searchComponent) {
				this.searchComponent.focus();
			}
		} else {
			this.setLastItemActiveForKeyboardNavigation();
		}
	}

	setDisplayItems() {
		const filteredCount = this.filteredItems.length;
		if (filteredCount <= this.MAX_ITEMS_WITHOUT_PAGING) {
			this.numberToDisplay = filteredCount;
		} else {
			this.numberToDisplay = Math.max(this.MAX_ITEMS_WITHOUT_PAGING - 1, this.numberToDisplay);
		}
		this.nextPageCount = Math.min(this.MAX_NEXT_PAGE, filteredCount - this.numberToDisplay);
		this.displayItems = this.filteredItems.slice(0, this.numberToDisplay);
	}

	// We will use a matching/hit score scheme that matches using each word in the searchText.
	// Results are sorted by hit score (highest first). Words are parsed using spaces.
	// 1) For each word in the search text,
	//    a) 1 hit point if matched against the item's searchable string
	//    b) If the item matched is a government type or Outlook item, then add an extra 1.5 point
	// 2) If the search string has multiple words and matches using the exact literal searchText, then add 1pt for every word
	// 3) If the search string matches the search item’s title exactly, then add +5 pts
	// 4) Sort by hit score and return any > 0
	filterAndSortItems = (items: Array<SearchItem>, searchText: string): Array<SearchItem> => {
		if (!searchText) {
			return [];
		}

		// Parse searchText into words array
		const words = searchText.split(' ');

		// Score each item based on logic described above; we'll use a transient type to store the hit score for later
		const scoredItems: Array<{item: SearchItem, hitScore: number}> = items.map(item => {
			// If there is a pinned selection, always return at the top
			if (item.pinned) {
				return { item: item, hitScore: 99 };
			}

			let hitScore = 0;
			// Step 1 - Attempt to match each word individually & score any hits
			words.forEach(word => {
				const hit = item.searchString.toLowerCase().includes(word.toLowerCase());
				const govtType = item.type === 'Government Type' || item.type === 'Financial Health';
				hitScore += hit ? (govtType ? 2.5 : 1) : 0;
			});

			// Step 2 - Attempt exact match on entire phrase (if multi-word)
			if (words.length > 1) {
				const hit = item.searchString.toLowerCase().includes(searchText.toLowerCase());
				hitScore += hit ? words.length : 0;
			}

			// Step 3 - Attempt exact word match on title text
			if (item.title.toString().toLowerCase() === searchText.toLowerCase()) {
				hitScore += 5;
			}

			return { item: item, hitScore: hitScore };
		});

		// Sort by hit score and filter out any with no hits (0 score); return the results
		return scoredItems.filter(x => x.hitScore > 0)
			.sort(sortByProperty(x => x.hitScore, false))
			.map(x => x.item);
	}

	// Assumes that the input is not drawn until values are ready
	onFocusIn($event: FocusEvent) {
		// Reset active item for keyboard navigation everytime search bar is focused
		this.resetKeyboardNavigation();

		if (this.filteredItems.length > 0) {
			this.showResults = true;
		}
	}

	onFocusOut($event: FocusEvent) {
		// Allow click event to process first (helps with proper event processing when virtual soft keyboard);
		// FocusOut from input closes virtual soft keyboard on mobile, changing viewport size, and causes intermittent
		// issues with resets (as click is processed on a non-combobox element, which resets combobox)
		setTimeout(($event: FocusEvent) => {
			// if we clicked on an item in the list or the add more data footer, skip the reset.
			// clicking an item will handle resetting of the combobox after click is handled.
			const clickedElement = $event.relatedTarget as HTMLElement;
			const searchResults = clickedElement?.className?.includes('search-results');
			const searchItem = clickedElement?.className?.includes('search-item');
			const addNextPage = clickedElement?.className?.includes('next-page');
			const searchInput = clickedElement?.className?.includes('search-input');
			if (!searchItem && !addNextPage && !searchInput && !searchResults) {
				this.reset();
			}
		}, 100, $event);
	}

	// Reset the results and viewable state (user clicks off the combobox or backspaces past the minChars)
	reset(hideMobileSearch = true) {
		this.showResults = false;
		this.numberToDisplay = 0;
		this.nextPageCount = 0;
		this.searchText = '';
		if (hideMobileSearch) {
			this.mobileSearchEnabled = false;
		}

		this.filteredItems = this.filterAndSortItems(this.items, this.searchText);
		this.resetKeyboardNavigation();
	}

	selectItem(item: SearchItem) {
		if (item.linkType === 'download' && typeof item.link === 'string') {
			this.downloadFile(item.link);
		} else if (item.linkType === 'routerLink' && typeof item.link !== 'string') {
			this.router.navigate(item.link).then();
		}
		this.reset();
	}

	// Listen to keyboard events for keyboard navigation (for accessibility)
	@HostListener('keydown', ['$event'])
	onKeydown(event: KeyboardEvent) {
		// if first item in the list is focused and the arrow key is pressed up, focus goes to search bar
		if (this.keyManager?.activeItem?.first && event.key === 'ArrowUp') {
			this.focusInput();
		} else {
			// if the last item in the list is focused and arrow key is pressed down,
			// focus goes to 'next page' div (if it exists)
			if (this.keyManager?.activeItem?.last && event.key === 'ArrowDown' && this.nextPage) {
				this.nextPage.nativeElement.focus();
			}

			// Main key manager function - used to navigate list items using up and down arrows
			this.keyManager?.onKeydown(event);
		}

	}

	// Reset active list item (for keyboard navigation)
	resetKeyboardNavigation() {
		this.keyManager?.setActiveItem(-1);
	}

	// Slight hack, allows key manager to set last list item as active dynamically (for keyboard navigation)
	setLastItemActiveForKeyboardNavigation() {
		this.keyManager?.setActiveItem(this.displayItems.length);
	}

	// Set last list item as active when focused on 'next page' div (for keyboard navigation)
	onNextPageFocus() {
		this.setLastItemActiveForKeyboardNavigation();
	}

	toggleSearchInput() {
		this.mobileSearchEnabled = !this.mobileSearchEnabled;
		if (!this.mobileSearchEnabled) {
			this.reset();
		} else {
			// move focus into text input box when enabled
			window.setTimeout(() => {
				this.searchComponent?.searchInput?.nativeElement.focus();
			},10);
		}
	}

	ngOnDestroy(): void {
		// this.logger.debug('Popover destroy');
		this.cancelAutoUpdate?.();
	}

	protected readonly overlayScrollDarkVertical = overlayScrollDarkVertical;
}
