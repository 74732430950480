import {ActivatedRouteSnapshot} from '@angular/router';
import {getDatasetsDetailFromRoute} from '../../services/resolvers/garfield-datasets-detail.resolver';
import {ReportKind} from './report-kind';
import {RouteParser} from '../route-parser';
import {Memoize} from 'typescript-memoize';
import {ByExpenditureObjectsDisplayOption, ReportOption, SingleYearReportOption,} from './report-option';
import {DatasetDetail} from '../../services/api/fit-api/models/datasets/dataset-type';
import {UserAccessLevel} from '../report';
import {HintInformation} from '../hintInformation';
import {User} from '../user';

/**
 * Report
 *
 * Base class for Report Instances
 *
 * Notes:
 * - options getter returns a concatenated list of report options and context options
 */
// Bridge pattern
// https://refactoring.guru/design-patterns/bridge
export interface ReportContext {
	getRowHierarchy(): Array<string>;
	getColumHierarchy(): Array<string>;
	getData(): Array<any>;
	restrictedTo: UserAccessLevel;
	supports: Array<ReportKind>;
	options: Array<new(...args: any[]) => ReportOption>;
}

// class GovernmentFinancesContext implements ReportContext {
// }
//
// class GovernmentTypeFinancesContext implements ReportContext {
// }

export class GovernmentComparisonContext implements ReportContext {
	getRowHierarchy(): Array<string> {
		return ['fsSectionId'];
	}
	getColumHierarchy(): Array<string> {
		return ['year'];
	}
	getData(): Array<any> {
		return [{ fsSectionId: 1, year: 2020, amount: 200888.88 }];
	}
	restrictedTo = UserAccessLevel.global;
	supports: Array<ReportKind> = [ReportKind.summary, ReportKind.revenues, ReportKind.revenuesOtherIncreases, ReportKind.expenditures, ReportKind.expendituresOtherDecreases, ReportKind.debtLiabilities];
	protected _optionRefs: Array<new(...args: any[]) => ReportOption> = [SingleYearReportOption];

	@Memoize()
	get options() {
		return this._optionRefs;
	}
}

export abstract class GarfieldReport {
	// todo change id to kind once refactor is complete (used in title dropdown)
	abstract readonly id: ReportKind;
	abstract readonly name: string;
	readonly hint?: string;
	restricted?: boolean;

	// todo remove once refactor is complete (used in title dropdown)
	readonly routeParam = undefined;

	// Abstract ctors are weird in TS
	// https://www.typescriptlang.org/docs/handbook/2/classes.html#abstract-construct-signatures
	// Array of concrete ReportOption ctors to be provided by the concrete Report implementation
	protected abstract readonly _optionRefs: Array<new(...args: any[]) => ReportOption>;

	// Constructed ReportOptions and ContextOptions
	@Memoize()
	get options(): Array<ReportOption> {
		const contextOptions = this.context.options?.map(option => new option(this));
		const reportOptions = this._optionRefs?.map(option => new option(this));
		return reportOptions.concat(contextOptions);
	}

	@Memoize()
	get restrictedTo(): UserAccessLevel | undefined {
		return this.restricted ? this.context.restrictedTo : undefined;
	}

	constructor(
		public readonly datasetsDetail: Array<DatasetDetail>,
		public readonly context: ReportContext,
		public readonly route?: ActivatedRouteSnapshot,
	) {
		// todo set displayYear logic, etc, from DataSets
	}

	/**
	 * Factory to build the appropriate Report based on the ActivatedRoute
	 */
	static async fromRoute(route: ActivatedRouteSnapshot) {
		const routeParser = new RouteParser(route);
		const datasets = await getDatasetsDetailFromRoute(route);
		const report = routeParser.report;

		return this.fromKind(route.data['context'], datasets, route, report);
	}

	/**
	 * Generate Report based on ReportKind
	 */
	static fromKind(
		context: ReportContext,
		datasets: DatasetDetail[],
		route: ActivatedRouteSnapshot,
		report?: ReportKind
	) {
		switch (report) {
			case ReportKind.summary:
				return new GarfieldSummaryReport(datasets, context, route);
			case ReportKind.revenues:
				return new GarfieldRevenuesReport(datasets, context, route);
			case ReportKind.revenuesOtherIncreases:
				return new GarfieldRevenuesAndOtherIncreasesReport(datasets, context, route);
			case ReportKind.expenditures:
				return new GarfieldExpendituresReport(datasets, context, route);
			case ReportKind.expendituresOtherDecreases:
				return new GarfieldExpendituresAndOtherIncreasesReport(datasets, context, route);
			case ReportKind.debtLiabilities:
				return new GarfieldDebtAndLiabilitiesReport(datasets, context, route);

			// note: return undefined if report not found (this way, a report that is not found can be handled by the report guard appropriately)
			default:
				return undefined;
		}
	}
}

export class GarfieldSummaryReport extends GarfieldReport {
	id = ReportKind.summary;
	name = 'Financial Summary';
	// routeParam = RouteParam.summary;
	override hint = HintInformation.FinancialReport.Summary;
	protected _optionRefs = [];
}

export class GarfieldRevenuesReport extends GarfieldReport {
	id = ReportKind.revenues;
	name = 'Revenues';
	// routeParam = RouteParam.revenues;
	override hint = HintInformation.FinancialReport.Revenues;
	protected _optionRefs = [];
}

export class GarfieldRevenuesAndOtherIncreasesReport extends GarfieldReport {
	id = ReportKind.revenuesOtherIncreases;
	name = 'Revenues and Other Increases';
	// routeParam = RouteParam.revenuesOtherIncreases;
	protected _optionRefs = [];
}

export class GarfieldExpendituresReport extends GarfieldReport {
	id = ReportKind.expenditures;
	name = 'Expenditures';
	// routeParam = RouteParam.expenditures;
	override hint = HintInformation.FinancialReport.Expenditures;
	protected _optionRefs = [ByExpenditureObjectsDisplayOption];
}

export class GarfieldExpendituresAndOtherIncreasesReport extends GarfieldReport {
	id = ReportKind.expendituresOtherDecreases;
	name = 'Expenditures and Other Decreases';
	// routeParam = RouteParam.expendituresOtherDecreases;
	protected _optionRefs = [ByExpenditureObjectsDisplayOption];
}

export class GarfieldDebtAndLiabilitiesReport extends GarfieldReport {
	id = ReportKind.debtLiabilities;
	name = 'Debt & Liabilities';
	// routeParam = RouteParam.debtLiabilities;
	override restricted = true;
	override hint = HintInformation.FinancialReport.DebtAndLiabilities;
	protected _optionRefs = [];
}

export const isApplicable = (report: GarfieldReport, user: User) => {
	// If report is restricted to global access level then user must has global access
	const matchesGlobalAccessRestriction = (r: GarfieldReport) => r.restrictedTo === UserAccessLevel.global ? user?.hasGlobalAccess() : true;

	// todo handle case where user has just mcag access when retrofitting for financial report
	// If report is restricted to mcag access level then user must has mcag access
	// const matchesMcagAccessRestriction = (r: GarfieldReport) => r.restrictedTo === UserAccessLevel.mcag ? user?.hasAccessToMcag(governmentId) : true;

	return matchesGlobalAccessRestriction(report);
}
