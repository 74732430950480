import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ComboBoxComponent} from '../inputs/combo-box/combo-box.component';
import {OmniSearchService} from './omni-search.service';
import {HotkeysService} from '../../services/hotkeys.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {LoggerService} from '../../services/logger.service';

@UntilDestroy()
@Component({
    selector: 'app-omni-search',
    imports: [CommonModule, ComboBoxComponent],
    templateUrl: './omni-search.component.html',
    styleUrls: ['./omni-search.component.scss']
})
export class OmniSearchComponent implements AfterViewInit {

	// Compact mode allows search to condense into a round magnifying glass button used to display and hide the combobox
	@Input() compactMode = false;

	@ViewChild(ComboBoxComponent) comboBox?: ComboBoxComponent;

	constructor(
		public service: OmniSearchService,
		private hotkeysService: HotkeysService,
		private logger: LoggerService
	) {
	}

	ngAfterViewInit(): void {
		this.hotkeysService.search.pipe(untilDestroyed(this)).subscribe(event => {
			if (!this.comboBox) {
				this.logger.warn('OmniSearch could not locate the search input to focus!')
			} else {
				this.comboBox.focusInput();
			}
		});
	}

}
