import {inject, Injectable} from "@angular/core";
import {PreloadingStrategy, Route} from "@angular/router";
import {mergeMap, Observable, of} from "rxjs";
import {UserService} from '../user.service';

/**
 * Custom preload strategy that only loads fit-admin components when the user is signed in with global access
 */
@Injectable({ providedIn: "root" })
export class UserBasedPreloadingStrategy extends PreloadingStrategy {
	userService = inject(UserService);

	preload(route: Route, fn: () => Observable<any>) {
		const pathBeforeSlash = route.path?.split('/')[0];
		const isFitAdminPath = pathBeforeSlash === 'fit-admin' || pathBeforeSlash === 'vintages' || pathBeforeSlash === 'records';

		return this.userService.user.pipe(mergeMap(user => {
			if (isFitAdminPath) {
				return user.hasGlobalAccess() ? fn() : of(null);
			} else {
				return fn();
			}
		}));
	}
}
